'use client'

import ArrowMenuItem from '@/components/main/button/ArrowMenuItem'
import CCP_MySwiper from '@/components/main/swiper/MySwiper'
import { useBreakpoint } from '@/utils/responsive'
import { Skeleton } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { useTranslations } from 'next-intl'
import SCP_SidebarMenuItem from './SidebarMenuItem'

/**
 * 
 * @param {*} param0 
 * @returns 
 * menu => [{
    "title": "บริการ",
    "icon": "stethoscope",
    "path": "/about/test", [actual path]
    "activeSlug" : 'test' [slug for active state]
    "hideOnMobile" : true [hide on mobile for custom page with only 1 main menu]
    "submenu": [
        {
            "title": "ข้อมูลทั่วไป",
            "activeSlug" : 'test-1',
            "path": "/center-clinic/mother-child/bangkok-skin-and-aesthetics-center/overview#content"
        },
        {
            "title": "โปรแกรมเลเซอร์",
            "activeSlug" : 'test-2',
            "path": "/center-clinic/mother-child/bangkok-skin-and-aesthetics-center/laser-programs#content"
        }
    ]
}]
activeSlugMainMenu => 'test'
activeSlugSubMenu => 'test-1'
 */

export default function SCP_SidebarMenu({
  menu,
  activeSlugMainMenu,
  activeSlugSubMenu,
  isMobileSwiper = false,
  slidesPerView = 'auto',
}) {
  // cannot detect empty menu here too late for sidebar initialize [use condition in parent component [server] instead]
  const __t = useTranslations()
  const isMd = useBreakpoint('md')
  const subMenuTotalAmount = menu.reduce(
    (total, item) => total + (item?.submenu ? item?.submenu?.length : 0),
    0
  )
  return (
    <>
      {/* mobile swiper menu */}
      {isMobileSwiper ? (
        <div className="md:tw-hidden">
          <CCP_MySwiper
            swiperClass="network-filter-swiper tw-mt-4"
            pagination={false}
            navigation={false}
            freeMode={true}
            breakpoints={{}}
            slidesPerView={slidesPerView}
            spaceBetween={12}
            initIndex={menu?.findIndex(
              (m) => m?.activeSlug === activeSlugMainMenu
            )}
            slides={menu.map((m, i) => (
              <div
                key={`sidebar-menu-${i}`}
                id={
                  m.activeSlug ? m.activeSlug : `sidebar-slide-main-menu-${i}`
                }
                className="tw-h-full"
              >
                <SCP_SidebarMenuItem
                  menu={m}
                  activeSlugMainMenu={activeSlugMainMenu}
                  activeSlugSubMenu={activeSlugSubMenu}
                />
              </div>
            ))}
            skeleton={
              <div className="tw-flex tw-items-center tw-space-x-4">
                {[0, 0].map((val, i) => (
                  <Skeleton
                    key={`sidebar-menu-skeleton-${i}`}
                    width={136}
                    height={80}
                    className="!tw-transform-none"
                  />
                ))}
              </div>
            }
          />
        </div>
      ) : null}
      {/* grid menu */}
      <div
        className={`${
          isMobileSwiper ? 'max-md:tw-hidden' : ''
        } sm:tw-grid-cols-3 ${menu.length < 4 ? 'tw-grid-cols-3' : 'tw-grid-cols-2'} md:tw-py-4 md:tw-px-8 md:tw-rounded-md tw-shadow-main-blue max-md:!tw-shadow-none md:tw-bg-white md:tw-flex tw-flex-col md:tw-space-y-4 tw-grid md:tw-gap-0 sm:tw-gap-6 tw-gap-2`}
      >
        {menu.map((m, i) => (
          <div key={`sidebar-menu-${i}`}>
            <SCP_SidebarMenuItem
              menu={m}
              activeSlugMainMenu={activeSlugMainMenu}
              activeSlugSubMenu={activeSlugSubMenu}
            />
          </div>
        ))}
      </div>
      {/* submenu mobile */}
      {subMenuTotalAmount > 1 && !isMd ? (
        <Accordion
          disableGutters
          elevation={0}
          square
          className={`${menu.every((x) => x?.hideOnMobile) ? '' : 'tw-mt-8'} !tw-bg-transparent !tw-border-y before:tw-hidden`}
        >
          {/* Title */}
          <AccordionSummary
            expandIcon={<i className="far fa-plus tw-text-primary" />}
            aria-controls="sidebar-content"
            id="sidebar-header"
          >
            <p className="tw-font-normal tw-text-primary">{__t('menu')}</p>
          </AccordionSummary>
          {/* Menu */}
          {menu.map((m, j) => (
            <AccordionDetails
              className="tw-grid tw-divide-y !tw-p-0"
              key={`sidebar-submenu-group-mobile-${j}`}
            >
              {m.submenu
                ? m.submenu.map((sm, k) => (
                    <ArrowMenuItem
                      menu={sm}
                      className="tw-py-2 sm:tw-px-4 tw-px-1"
                      key={`center-submenu-mobile-${k}-${j}`}
                      isActive={sm?.activeSlug === activeSlugSubMenu}
                    />
                  ))
                : null}
            </AccordionDetails>
          ))}
        </Accordion>
      ) : null}
    </>
  )
}
