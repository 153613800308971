'use client'
import { Link } from '@/navigation'
import { Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'

export default function CCP_Breadcrumb({ menus }) {
  // Wait for font awesome
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <div className="md:tw-px-6 tw-px-4 tw-w-full sm:tw-py-3 tw-py-2  tw-flex tw-flex-wrap tw-items-center">
      {isLoaded ? (
        <>
          <Link prefetch={false} href="/" className="tw-mr-2">
            <i className="far fa-house tw-text-primary/50 hover:tw-text-primary tw-duration-200 tw-relative tw-bottom-[1px]" />
          </Link>
          <i className="fas fa-caret-right tw-text-sm tw-text-primary/20 tw-mr-2 tw-h-4" />
          {menus && menus.length
            ? menus.map((menu, i) =>
                menu && menu.label ? (
                  <div
                    key={`breadcrumb-menu-${i}`}
                    className="tw-flex tw-items-center    tw-space-x-2 tw-mr-2 tw-my-1"
                  >
                    <Link prefetch={false} href={menu?.slug ? menu?.slug : '#'}>
                      <div
                        className={`tw-text-primary/50 tw-uppercase md:tw-text-sm tw-text-xs hover:tw-text-primary tw-duration-200 tw-max-w-[50vw] tw-line-clamp-1 tw-w-fit  ${
                          menu?.active ? 'tw-font-bold' : ''
                        }`}
                      >
                        {menu?.label}
                      </div>
                    </Link>
                    <i className="fas fa-caret-right tw-text-sm tw-text-primary/20" />
                  </div>
                ) : null
              )
            : null}
        </>
      ) : (
        <Skeleton
          variant="rounded"
          width={180}
          height={28}
          className="max-sm:!tw-h-6"
        />
      )}
    </div>
  )
}
