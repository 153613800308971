export default function scrollToID(id, offset) {
  const element = document.getElementById(id)
  if (element) {
    // get screen size
    const screen = window.innerWidth
    // if screen is less than 1080px
    if (screen < 1080 && !offset) {
      offset = -200
    }
    // if screen is more than 1080px
    if (screen >= 1080 && !offset) {
      offset = -300
    }
    const elementTop = element.offsetTop - offset
    window.scroll({
      top: elementTop,
      behavior: 'smooth',
    })
  }
}
