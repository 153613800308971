'use client'

import { useBreakpoint } from '@/utils/responsive'
import { Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'
import Sticky from 'react-stickynode'

export default function CCP_StickyLayout({
  stickyElem,
  contentElem,
  isReverse,
  top = 48,
  isLoading = false,
  customHasSidebar = true,
}) {
  const isSm = useBreakpoint('sm')
  const [hasSidebar, setHasSidebar] = useState(customHasSidebar)
  /**
   * Sticky
   */
  useEffect(() => {
    setHasSidebar(stickyElem?._payload?.value?.props || stickyElem?.props)
  }, [stickyElem])

  return (
    <>
      {/* Content */}
      <div
        className={`${hasSidebar ? 'max-md:tw-gap-8' : ''} tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-w-full tw-relative tw-z-10`}
      >
        {/* Left */}
        <div
          id="sticky-main"
          className={`tw-col-span-1 ${isReverse ? 'md:tw-col-start-4' : ''}`}
        >
          <Sticky enabled={isSm} bottomBoundary="#sticky-main" top={top}>
            {isLoading ? (
              <>
                <Skeleton variant="rounded" width="100%" height={400} />
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={54}
                  className="!tw-mt-8"
                />
              </>
            ) : (
              stickyElem
            )}
          </Sticky>
        </div>
        {/* Right */}
        <div
          id="content"
          className={`
        ${hasSidebar ? 'md:tw-col-span-3' : 'md:tw-col-span-4 md:!tw-px-0'}
        ${
          isReverse
            ? 'tw-row-start-1 sm:tw-col-start-1 ltr:md:tw-pr-10 rtl:md:tw-pl-10'
            : 'ltr:md:tw-pl-10 rtl:md:tw-pr-10'
        }`}
        >
          {isLoading ? (
            <Skeleton variant="rounded" width="100%" className="!tw-h-screen" />
          ) : (
            contentElem
          )}
        </div>
      </div>
    </>
  )
}
