import { Link } from '@/navigation'

const ArrowMenuItem = ({ menu, className, isActive }) => {
  return (
    <Link
      prefetch={false}
      href={menu.path ? menu.path : '/'}
      target={menu?.path?.includes('http') ? '_blank' : '_self'}
      className={`tw-flex tw-items-start tw-space-x-2 tw-group ${className} ${
        isActive ? '[&>*]:!tw-text-bgh-blue [&>p]:!tw-font-bold' : ''
      }`}
    >
      <i className="tw-text-bgh-gray group-hover:tw-text-bgh-blue tw-duration-200 far fa-arrow-circle-right tw-mt-1 rtl:tw-rotate-180" />
      <p className="tw-text-bgh-dark group-hover:tw-text-bgh-blue tw-duration-200">
        {menu.title}
      </p>
    </Link>
  )
}

export default ArrowMenuItem
